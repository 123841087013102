import styled, { css } from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const Container = styled.div`
  width: 100%;
  border: 1px solid ${colors.primary[100]};
  background-color: ${colors.base.white};
  border-radius: 8px;
  margin: 0px auto;
  overflow: hidden;
`;

export const StyledTable = styled.table`
  width: 100%;

  thead .table-row {
    background-color: transparent;
  }

  tbody:nth-child(even) .table-row {
    background-color: ${colors.primary[50]};
  }
`;

export const TRow = styled.tr`
  display: grid;
  grid-template-columns: repeat(${({ numOfColumns }) => numOfColumns}, 1fr);
`;

export const TColumn = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 4px;

  &.align-left {
    align-items: flex-start;
    padding: 16px 20px;

    p,
    .heading,
    .subhead,
    .subhead p {
      text-align: left;
    }
  }

  .check-icon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${colors.green[500]};
  }

  .dash-icon {
    width: 48px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heading {
    ${font('text', 'xs', '700')}
    text-align: center;
    color: ${colors.gray[900]};

    ${atMinWidth.xs`
      ${font('text', 'sm', '700')}
    `}

    ${atMinWidth.sm`
      ${font('text', 'md', '700')}
    `}
  }

  .subhead {
    ${font('text', 'xs', '400')}
    text-align: center;
    color: ${colors.gray[700]};

    p {
      ${font('text', 'xs', '400')}
      text-align: center;
      color: ${colors.gray[700]};
    }

    ul {
      text-align: left;
      gap: 16px;
    }
  }
`;

export const THead = styled.th`
  ${TColumn}
`;

export const TCell = styled.td`
  ${TColumn}
`;
